<template>
  <section class="page-container">
    <div ref="toolbar" class="toolbar">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-input v-model="filters.schoolName" placeholder="名称" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="list"> 查询 </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAdd"> 新增 </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div ref="mainContent" :style="{ height: mainHeight }">
      <el-table
        v-loading="listLoading"
        height="100%"
        :data="results"
        highlight-current-row
        stripe
        border
      >
        <el-table-column prop="id" label="编号" width="60" />
        <el-table-column prop="schoolName" label="学校名称" width="320" />
        <el-table-column prop="schoolCode" label="学校外标" />
        <el-table-column prop="region" label="地区" />
        <el-table-column prop="city" label="城市" />
        <el-table-column prop="level" label="等级" />
        <el-table-column prop="schoolType" label="类型" />

        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button-group>
              <el-tooltip
                class="item"
                effect="dark"
                content="编辑"
                placement="left-start"
              >
                <el-button
                  size="mini"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.$index, scope.row)"
                >
                </el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="删除"
                placement="right-start"
              >
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.$index, scope.row)"
                >
                </el-button>
              </el-tooltip>
            </el-button-group>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty />
        </template>
      </el-table>
    </div>

    <div ref="pagebar" class="pagebar">
      <el-pagination
        :page-sizes="[20, 50, 100, 300]"
        :current-page="page.page"
        :page-size="page.limit"
        :total="page.total"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePageChange"
        @size-change="handleLimitChange"
      />
    </div>

    <el-dialog
      :visible.sync="addFormVisible"
      :close-on-click-modal="false"
      title="新增"
    >
      <el-form ref="addForm" :model="addForm" label-width="80px">
        <el-form-item label="学校名称">
          <el-input v-model="addForm.schoolName" />
        </el-form-item>
        <el-form-item label="学校外标">
          <el-input
            v-model="addForm.schoolCode"
            placeholder="请前往支付宝查询"
          />
        </el-form-item>
        <el-form-item label="地区" prop="region">
          <el-input v-model="addForm.region" />
        </el-form-item>
        <el-form-item label="城市" prop="city">
          <el-input v-model="addForm.city" />
        </el-form-item>
        <el-form-item label="等级" prop="level">
          <el-select v-model="addForm.level" placeholder="请选择等级">
            <el-option value="本科" label="本科" />
            <el-option value="专科" label="专科" />
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="schoolType">
          <el-select v-model="addForm.schoolType" placeholder="请选择类型">
            <el-option value="民办" label="民办" />
            <el-option value="公办" label="公办" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false"> 取消 </el-button>
        <el-button
          :loading="addLoading"
          type="primary"
          @click.native="addSubmit"
        >
          提交
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="editFormVisible"
      :close-on-click-modal="false"
      title="编辑"
    >
      <el-form ref="editForm" :model="editForm" label-width="80px">
        <el-input v-model="editForm.id" type="hidden" />
        <el-form-item label="学校名称">
          <el-input v-model="editForm.schoolName" />
        </el-form-item>
        <el-form-item label="学校外标">
          <el-input v-model="editForm.schoolCode" />
        </el-form-item>
        <el-form-item label="地区" prop="region">
          <el-input v-model="editForm.region" />
        </el-form-item>
        <el-form-item label="城市" prop="city">
          <el-input v-model="editForm.city" />
        </el-form-item>
        <el-form-item label="等级" prop="level">
          <el-select v-model="editForm.level" placeholder="请选择等级">
            <el-option value="本科" label="本科" />
            <el-option value="专科" label="专科" />
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="schoolType">
          <el-select v-model="editForm.schoolType" placeholder="请选择类型">
            <el-option value="民办" label="民办" />
            <el-option value="公办" label="公办" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false"> 取消 </el-button>
        <el-button
          :loading="editLoading"
          type="primary"
          @click.native="editSubmit"
        >
          提交
        </el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import mainHeight from '@/utils/mainHeight'
export default {
  name: 'Tags',
  data() {
    return {
      mainHeight: '300px',
      results: [],
      listLoading: false,
      page: {},
      filters: {},
      selectedRows: [],

      addForm: {
        schoolName: '',
        region: '',
        city: '',
        level: '',
        schoolType: ''
      },
      addFormVisible: false,
      addLoading: false,

      editForm: {
        id: '',
        schoolName: '',
        region: '',
        city: '',
        level: '',
        schoolType: ''
      },
      editFormVisible: false,
      editLoading: false
    }
  },
  mounted() {
    mainHeight(this)
    this.list()
  },
  methods: {
    list() {
      const me = this
      me.listLoading = true
      const params = Object.assign(me.filters, {
        page: me.page.page,
        limit: me.page.limit
      })
      this.axios
        .form('/api/admin/school/list', params)
        .then((data) => {
          me.results = data.results
          me.page = data.page
        })
        .finally(() => {
          me.listLoading = false
        })
    },
    handlePageChange(val) {
      this.page.page = val
      this.list()
    },
    handleLimitChange(val) {
      this.page.limit = val
      this.list()
    },
    handleSelectionChange(val) {
      this.selectedRows = val
    },
    handleAdd() {
      this.addForm = {}
      this.addFormVisible = true
    },
    addSubmit() {
      const me = this
      this.axios
        .form('/api/admin/school/create', this.addForm)
        .then((data) => {
          me.$message({ message: '提交成功', type: 'success' })
          me.addFormVisible = false
          me.list()
        })
        .catch((rsp) => {
          me.$notify.error({ title: '错误', message: rsp.message })
        })
    },
    handleEdit(index, row) {
      const me = this
      this.axios
        .get('/api/admin/school/' + row.id)
        .then((data) => {
          me.editForm = Object.assign({}, data)
          me.editFormVisible = true
        })
        .catch((rsp) => {
          me.$notify.error({ title: '错误', message: rsp.message })
        })
    },
    async handleDelete(index, row) {
      try {
        const flag = await this.$confirm('是否确认取消删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        if (flag) {
          try {
            await this.axios.form('/api/admin/school/delete', { id: row.id })
            this.list()
            this.$message.success('操作成功')
          } catch (err) {
            this.$notify.error({ title: '错误', message: err.message || err })
          }
        }
      } catch (e) {
        this.$message.success('操作已取消')
      }
    },
    editSubmit() {
      const me = this
      this.axios
        .form('/api/admin/school/update', me.editForm)
        .then((data) => {
          me.list()
          me.editFormVisible = false
        })
        .catch((rsp) => {
          me.$notify.error({ title: '错误', message: rsp.message })
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
